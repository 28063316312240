import React, { createContext, useState } from "react";
import { PriceProp } from "../../interface";
export const UserContext = createContext({});

export const UserProvider: React.FC<{ children: React.ReactNode }> = (
  props
) => {
  const [serviceList, setServiceList] = useState([]);
  const [price, setPrice] = useState(0);
  const [buyBtnPrice, setBuyBtnPrice] = useState(0);
  const [posts, setPosts] = useState<PriceProp[]>([]);
  const [response, setResponse] = useState({});
  const [selectCurrency, setSelectCurrency] = useState<string>("$");
  const [userName, setUserName] = useState("");
  const [orderId, setOrderId] = useState([]);


  return (
    <UserContext.Provider
      value={{
        serviceList,
        setServiceList,
        price,
        setPrice,
        posts,
        setPosts,
        response,
        setResponse,
        selectCurrency,
        setSelectCurrency,
        buyBtnPrice,
        setBuyBtnPrice,
        userName,
        setUserName,
        orderId, 
        setOrderId
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
