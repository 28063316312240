
import "./src/Global/Global.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import React from "react";
import { UserProvider } from "./src/components/Context";
export const wrapRootElement = ({ element }) => {
  return (
    <UserProvider>
      <ToastContainer/>
        {element}
    </UserProvider>
  );
};
